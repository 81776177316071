import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';
import GetStarted from "../components/CTA/GetStarted"
import ReviewCarousel from "../components/carousel/ReviewCarousel"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { sortReviewsByDate } from "../utils/utils"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer";
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { getStarted } from "../cms/fields/getStarted"
var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

const SingleReview = ({ data, pageContext }) => {
  var language
  if (pageContext && pageContext.language) {
    language = pageContext.language
  }
  const post = data.singleReview.nodes[0]
  const getStartedData = data.allTemplateSettingsJson.nodes[0].getStarted
  var reviewTitle = post.title
  const reviewPool = data.relatedReviews.nodes
  var referrerPool = data.relatedReviewsProviders.nodes

  function getRelatedReviews(singleReview, allReviews) {
    var singleReviewJustProceduresDoneNames = getOnlyProceduresDoneNames(
      singleReview
    )

    // Get an array of the patient's procedures
    function getOnlyProceduresDoneNames(singleReviewObj) {
      var singleReviewJustProceduresDoneNames = []

      for (let i = 0; i < singleReviewObj.proceduresDone.length; i++) {
        singleReviewJustProceduresDoneNames.push(
          singleReviewObj.proceduresDone[i].procedureDone.procedureName
        )
      }
      return singleReviewJustProceduresDoneNames
    }

    var reviewPoolWithJustNames = getAllReviewsWithProceduresDoneNames(
      allReviews
    )

    // Add array of procedures done names to all review objects
    function getAllReviewsWithProceduresDoneNames(allReviews) {
      var reviewPoolWithJustNames = []
      // Make an array of just the names of the procedures done
      for (let i = 0; i < allReviews.length; i++) {
        let relatedProceduresDone = []
        for (let j = 0; j < allReviews[i].proceduresDone.length; j++) {
          relatedProceduresDone.push(
            allReviews[i].proceduresDone[j].procedureDone.procedureName
          )

          let patientInfo = allReviews[i]
          patientInfo.allProceduresJustNames = relatedProceduresDone
          reviewPoolWithJustNames.push(patientInfo)
          break;
        }
      }
      return reviewPoolWithJustNames;
    }

    var relatedReviews = getReviewsWithAnyMatchingProcedures(
      singleReviewJustProceduresDoneNames,
      reviewPoolWithJustNames
    )

    // Compare patients to see if any of their procedures done match, if so, add to array and return it
    function getReviewsWithAnyMatchingProcedures(
      singleReviewProcedureNames,
      allReviewsWithNames
    ) {
      let relatedReviews = []
      // Compares the single review's procedures done names with all the reviews' procedures done names, then pushes matches into another array
      let found = false

      for (let i = 0; i < allReviewsWithNames.length; i++) {
        if (reviewTitle !== allReviewsWithNames[i].title) {
          found = false
          found = singleReviewProcedureNames.some(r =>
            allReviewsWithNames[i].allProceduresJustNames.includes(r)
          )
          if (found === true) {
            relatedReviews.push(allReviewsWithNames[i])
            continue
          }
        }
      }
      return relatedReviews
    }

    return relatedReviews
  }

  var relatedReviews = getRelatedReviews(post, reviewPool)

  relatedReviews = sortReviewsByDate(relatedReviews)
  referrerPool = sortReviewsByDate(referrerPool)

  // Wisdom Teeth Removal is too long for the button; renamed to Wisdom Teeth
  var relatedProcedures = post.proceduresDone.map(procedure => {
    return (
      <Button
        key={procedure.procedureDone.procedureUrl}
        buttonText={
          procedure.procedureDone.procedureName === "Wisdom Teeth Removal"
            ? "Wisdom Teeth"
            : procedure.procedureDone.procedureName
        }
        href={"/" + procedure.procedureDone.procedureUrl}
      />
    )
  })

  var checkIfReferrerReviews = function() {
    if (post.reviewType === "Provider" && referrerPool.length > 0) {
      return true
    } else {
      return false
    }
  }

  var reviewCarouselHeading = classNames({
    "referrer-carousel-heading": checkIfReferrerReviews(),
  })

  var showRelatedProcedureButtons = function() {
    if (post.reviewType !== "Provider" && !post.otherProcedure) {
      return true
    }
    return false
  }

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO title={post.metaTitle} description={post.metaDescription} />
        <section className="com-content">
          <Button
            buttonText={language === "es" ? "ATRÁS" : "BACK"}
            goBack
            href={
              language === "es"
                ? "/es/historias-de-pacientes"
                : "/patient-stories/"
            }
          />
          <div className="columns review-intro-text">
            <div className="column is-5"></div>
            <div className="column">
              <h1 dangerouslySetInnerHTML={createHtml(post.heading)}></h1>
              <div>
                <h6 className="video-review-head">
                  {language === "es" ? "Revisión de Video" : "Video Review"}
                </h6>
                <span className="review-small-text">
                  {post.youtubeVideoLength}
                </span>
                <span className="review-small-text">{post.reviewLanguage}</span>
              </div>
              <p>{post.subheading}</p>
            </div>
            <div className="column is-4"></div>
          </div>

          {post.youtube && (
            <div className="columns review-video-section">
              <div className="column is-1"></div>
              <div className="column">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                  playing={true}
                  overVideo
                  hasWatchVideo
                  language={language}
                >
                  <NuvoImage
                    useAR
                    cloudName="nuvolum"
                    publicId={post.mainPhotoPublicId}
                    width="auto"
                    responsive
                    responsiveUseBreakpoints="true"
                    className="theater-image"
                  ></NuvoImage>
                </TheaterVideo>
              </div>
              <div className="column is-1"></div>
            </div>
          )}

          {language !== "es" && (
            <div className="columns review-quote-section">
              <div className="column is-4"></div>
              <div className="column">
                <h3>{post.reviewerName}'s Story</h3>
                <MarkdownViewer markdown={post.quoteWithInlinks ?`"${post.quoteWithInlinks}"` : `"${post.quote}"`} className="review-quote-text" />
                {showRelatedProcedureButtons() && (
                  <>
                    <h5 className="learn-more-about-review-page">Learn More About</h5>
                    <ButtonGroup alignLeft>{relatedProcedures}</ButtonGroup>
                  </>
                )}
                {post.extraText && <MarkdownViewer markdown={post.extraText} />}
              </div>
              <div className="column is-4"></div>
            </div>
          )}

          {(relatedReviews.length > 0 || checkIfReferrerReviews()) &&
            language !== "es" && (
              <>
                <div className="columns">
                  <div className="column is-4"></div>
                  <div className="column">
                    <h3
                      className={reviewCarouselHeading}
                      style={{ paddingBottom: "32px" }}
                    >
                      Watch Other Reviews Like {post.reviewerName}'s
                    </h3>
                  </div>
                </div>
                <div
                  className="carousel-section-match"
                  style={{ paddingBottom: "0" }}
                >
                  <ReviewCarousel
                    id="singleReview"
                    noShuffle={true}
                    relatedReviews={
                      post.reviewType === "Patient"
                        ? relatedReviews
                        : referrerPool
                    }
                  />
                </div>
              </>
            )}

          <GetStarted
            className="get-started-cta"
            sideColumnIs={4}
            subheading={
              language === "es" ? "Empecemos" : getStartedData.subheading
            }
            heading={
              language === "es"
                ? "¿Listo para programar su cita?"
                : getStartedData.heading
            }
            paragraph={
              language === "es" ? (
                <p>
                  Nuestra práctica se enorgullece de servir a la comunidad local
                  y la región circundante de Mount Pleasant, SC. Nos tomamos más
                  tiempo para explicar los detalles del procedimiento y las
                  opciones de anestesia para asegurarnos de que se sienta lo más
                  cómodo posible. También responderemos cualquier pregunta que
                  pueda tener porque creemos en mantener a nuestros pacientes
                  informados sobre su tratamiento y salud bucal. También puede
                  leer sobre lo que debe traer a su primera cita y qué esperar.
                  ¿Estás listo para una consulta? ¡El{" "}
                  <Link to="/es/robert-b-bryan-dds-oklahoma-city-ok/">
                    Dr. Bryan
                  </Link>{" "}
                  y el{" "}
                  <Link to="/es/jeremy-c-goodson-dds-oklahoma-city-ok/">
                    Dr. Goodson
                  </Link>{" "}
                  esperan conocerte!
                </p>
              ) : (
                <div
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(getStartedData.blurb)
                  )}
                ></div>
              )
            }
            buttonText={language === "es" ? "Contáctenos" : "Let's Get Started"}
            buttonUrl={
              language === "es"
                ? "/es/contactenos-oklahoma-city-ok/"
                : "/get-started/"
            }
          />
        </section>
      </Layout>
    </SharedStateProvider>
  )
}

export default SingleReview

export const query = graphql`
  query($id: String!, $title: String!) {
    allTemplateSettingsJson {
      nodes {
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
        }
      }
    }
    singleReview: allReviewsJson(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        otherProcedure
        quote
        quoteWithInlinks
        extraText
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        youtubeVideoLength
      }
    }
    relatedReviews: allReviewsJson(
      filter: {
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
        title: { ne: $title }
      }
    ) {
      nodes {
        thumbnailPublicId
        title
        reviewerName
        reviewType
        monthYear {
          month
          year
        }
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
      }
    }
    relatedReviewsProviders: allReviewsJson(
      filter: {
        reviewType: { eq: "Provider" }
        reviewLanguage: { eq: "ENG" }
        title: { ne: $title }
      }
    ) {
      nodes {
        thumbnailPublicId
        title
        reviewerName
        reviewType
        monthYear {
          month
          year
        }
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
      }
    }
  }
`
